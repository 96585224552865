<style scoped>
.swiper {
  position: relative;
  background-color: #082e42;
  overflow: hidden;
}
.swiper-content {
  height: 100%;
}
.swiper-content img{
  display: block;
  height: 100%;
  width: 100%;
}
.div-imgs {
  cursor: pointer;
}
.div-img{
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat:no-repeat;
  position: relative;
}
.image-title{
  padding: 5px 15px;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.swiper-dian {
  position: absolute;
  bottom: 14px;
  width: 100%;
  text-align: center;
}
.dian-view {
  display: inline-block;
}
.dian {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgba(135,140,145, 0.8);
  text-align: center;
  line-height: 18px;
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
  cursor: pointer;
}
.dian-mar{
  margin-right: 12px;
}
.dian-on {
  background-color: rgba(5, 70, 192, 0.8);
}
.arrows{
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all .2s;
}
.swiper:hover .arrows{
  opacity: 1;
}
.arrows.left{
  left: 7px;
}
.arrows.right{
  right: 7px;
}
.arrows-item{
  height: 12px;
  width: 12px;
  border-right: 2px solid #cdcdcd;
  border-bottom: 2px solid #cdcdcd;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(135deg);
}
.arrows-item:hover{
  border-right: 2px solid #568dfc;
  border-bottom: 2px solid #568dfc;
}
.arrows-box{
  /* height: .6rem; */
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, rgba(0,100,255,.9), rgba(0,100,255,.0));
  display: flex;
  justify-content: space-between;
  padding: .25rem .3rem .2rem .3rem;
}
.arrows-box-item{
  height: .25rem;
  width: .25rem;
  border-right: 3px solid #64FBFB;
  border-bottom: 3px solid #64FBFB;
  transform: rotate(135deg);
  cursor: pointer;
}
.arrows-box-num{
  line-height: .25rem;
  color: #64FBFB;
  font-size: .25rem;
  letter-spacing: -.03rem
}
</style>

<template>
  <div ref="box" :style="templateStyle">
    <div class="swiper" :style="swiperStype">
      <div class="swiper-content clearfix" :style="contentStype">
        <div
          v-for="(item, index) of config.list"
          :key="index"
          class="swiper-item pull-left"
          :class="{'cursor': item.url}"
          :style="swiperStype"
        >
          <div v-if="config.type == 'image'"
            class="div-img" :style="`background-image: url(${item.image});${imageStyle}`"
            @click="imgClick(index)">
          </div>
          <div v-if="config.type == 'list'"
            class="div-img" :style="`${imageStyle}`">
              <slot :name="index"></slot>
          </div>
        </div>
      </div>
      <div v-if="swiperIndicator" class="swiper-dian">
        <div class="dian-view">
          <div
            v-for="(item, index) of swiperIndicatorNumber"
            :key="index"
            class="dian"
            :class="{'dian-on':index == config.index, 'dian-mar': index < swiperIndicatorNumber - 1}"
            @click="dianClick(index)">{{index + 1}}</div>
        </div>
      </div>
      <div v-if="swiperArrows == 1" class="arrows left" @click="arrowsClick('right')">
        <div class="arrows-item"></div>
      </div>
      <div v-if="swiperArrows == 1" class="arrows right" @click="arrowsClick('left')">
        <div class="arrows-item" style="transform: rotate(-45deg);margin-left: -8px;"></div>
      </div>
      <div class="arrows-box" v-if="swiperArrows == 2">
        <div class="arrows-box-item" @click="arrowsClick('right')"></div>
        <div class="arrows-box-num" style="width: 100%;text-align: center;">{{config.index + 1}} / {{swiperIndicatorNumber}}</div>

        <div class="arrows-box-item" style="transform: rotate(-45deg);" @click="arrowsClick('left')"></div>
      </div>
    </div>
  </div>
</template>

<script>
/*
  import ComponentTemplate from '@/components/ComponentTemplate'
  <ComponentTemplate></ComponentTemplate>

*/

export default {
  name: 'Swiper',
  data() {
    return {
      swiperIndicator: false, // 是否显示指示点 默认不显示
      swiperArrows: false, // 是否显示左右箭头 默认不显示
      swiperIndicatorNumber: '', // 指示点个数
      swiperCircular: false, // 是否收尾衔接 默认不衔接
      swiperAnimationTime: 300,
      templateStyle: '', // 组件的样式，主要设置高宽背景颜色
      swiperHeight: '', // swiper高度
      swiperWidth: '', // swiper宽度
      swiperStype: '', // swiper外层样式
      contentStype: '', // swiper滑动样式
      imageStyle: '', // 图片样式

      config: {
        // swiper配置
        type: '',
        width: '',
        left: 0,
        index: 0,
        list: '',
      },
      container: {
        // 实时记录Touch事件的 时间和距离
        time: { start: 0, end: 0 },
        touch: { start: 0, end: 0 },
        left: ''
      },
      interavlTime: 3000,
      interavl: null,
    }
  },
  components: {},
  props: {
    swiperConfig: {
      type: Object,
    },
    list: {
      default: [],
    },
    zanting: {
      type: Boolean
    }
  },
  watch: {
    list(n, o) {
      if (n) {
        if (o.length < 1) {
          this.initialData();
          if (this.$props.swiperConfig.autoplay) {
            if (this.$props.swiperConfig.interval) {
              this.interavlTime = this.$props.swiperConfig.interval;
            }
            this.autoplayFun(this.interavlTime, this.$props.swiperConfig.circular);
          }
        }
      }
    }
  },
  methods: {
    // 初始化数据
    initialData() {
      const config = this.$props.swiperConfig;
      // 设置swioer宽度
      this.swiperWidth = this.$refs.box.offsetWidth;
      // 设置swioer宽度
      this.swiperHeight = this.$refs.box.offsetHeight;
      // 如果显示指示点
      if (config.indicator) {
        // 显示指示点
        this.swiperIndicator = true;
      }
      // 设置指示点个数
      this.swiperIndicatorNumber = this.$props.list.length;
      // 如果有滑动时间，设置滑动时间，如果没有，默认300
      if (config.animationTime) {
        this.swiperAnimationTime = config.animationTime;
      }
      // 设置外层的高和宽
      this.swiperStype = `height: ${this.swiperHeight}px;width: ${this.swiperWidth}px;`;
      // 配置swiper类型 image和list
      this.config.type = config.type;
      // 如果首尾连接，图片专用
      if (config.circular) {
        // 拿到图片数组数组
        // 复制数组第一个对象和最后一个对象，分别放入相应位置
        const array = this.$props.list;
        // eslint-disable-next-line camelcase
        const s_img = array[0];
        // eslint-disable-next-line camelcase
        const e_img = array[array.length - 1];
        array.unshift(e_img);
        array.push(s_img);
        // 设置swiper滑动元素的宽度，数组列表，滑动元素的初始位置
        this.config.width = array.length * this.swiperWidth;
        this.config.list = array;
        this.config.left = -this.swiperWidth;
        // 标记首尾连接
        this.swiperCircular = true;
      } else {
        // 收尾不衔接
        this.config.list = this.$props.list;
        this.config.width = this.$props.list.length * this.swiperWidth;
        this.swiperCircular = false;
      }
      // 左右箭头显示
      if (config.arrows) {
        this.swiperArrows = config.arrows;
      }
      // 根据首次显示的值，做对应设置
      if (config.index) {
        const num = parseInt(config.index);
        // 设置显示索引
        this.config.index = parseInt(config.index);
        // 计算滑动元素距离父元素left边的X轴距离
        this.config.left = -(num * this.swiperWidth) + this.config.left;
        this.replaceStyle(this.config.left);
      }
      // 设置滑动位置
      this.replaceStyle(this.config.left);

      if (config.style || config.type === 'image') {
        // 图片样式设置  一般用于type: 'image'
        if (config.style) {
          this.initialStyle(config.style);
        }
      }
    },

    // 设置并返回数据
    setData(type, juli, autoplay) {
      // type: 1自动  2手动
      // juli: 滑动元素对于父元素左边的X轴距离
      // autoplay 只有在不收尾链接并且自动播放到最后一个要重新回到第一个时值才是'fang'
      this.config.left = juli
      const trueIndex = Math.abs(this.config.left) / this.swiperWidth;
      let realityIndex = '';
      if (!this.swiperCircular) {
        // 不衔接
        if (autoplay === 'fang') {
          // 不收尾链接并且自动播放到最后一个要重新回到第一个
          this.config.left = 0;
          realityIndex = 0;
        } else {
          realityIndex = trueIndex;
        }
      } else {
        // 衔接
        if (trueIndex === 0) {
          const that = this;
          that.config.index = that.config.list.length - 3;
          realityIndex = that.config.list.length - 3;
          that.config.left = -(
            (that.config.list.length - 3) * that.swiperWidth +
            that.swiperWidth
          )
          setTimeout(() => {
            that.replaceStyle(
              -(
                (that.config.list.length - 3) * that.swiperWidth +
                that.swiperWidth
              )
            );
          }, that.swiperAnimationTime);
        } else if (trueIndex === this.config.list.length - 1) {
          const that = this;
          that.config.index = 0;
          realityIndex = 0;
          that.config.left = -(that.swiperWidth);
          setTimeout(() => {
            that.replaceStyle(-(that.swiperWidth));
          }, that.swiperAnimationTime);
        } else {
          realityIndex = trueIndex - 1;
        }
      }
      const obj = {
        type: type,
        new: realityIndex,
        old: this.config.index,
      }
      this.config.index = realityIndex;
      if (type == 1) {
        this.$emit('SwiperIndexFun2', obj);
      } else {
        this.$emit('SwiperIndexFun', obj);
      }
    },

    // 替换样式
    replaceStyle(left, inertiaTime = 0) {
      this.contentStype = `width: ${this.config.width}px;transform: translate3d(${left}px, 0px, 0px);transition: all ${inertiaTime}s;`;
    },

    // 初始化样式(只限type: image)
    initialStyle(style) {
      // eslint-disable-next-line camelcase
      let radius_style = '';
      // eslint-disable-next-line camelcase
      let shadow_style = '';
      // eslint-disable-next-line camelcase
      let margin_style = '';
      if (style.radius) {
        // 圆角
        // eslint-disable-next-line camelcase
        radius_style = `border-radius: ${style.radius}px;overflow: hidden;`;
      }
      if (style.shadow) {
        // 阴影
        // eslint-disable-next-line camelcase
        shadow_style = `height: ${this.swiperHeight - 14}px;margin-top: 7px;box-shadow: 0 2px 6px rgb(0 0 0 / 70%);`;
      }
      if (style.margin) {
        // 左右边距
        const wid = this.swiperWidth - parseInt(style.margin) * 2;
        // eslint-disable-next-line camelcase
        margin_style = `width: ${wid}px;margin: auto;`;
      }
      // eslint-disable-next-line camelcase
      this.imageStyle = radius_style + margin_style + shadow_style;
    },

    // 自动切换
    autoplayFun(time, circular) {
      const that = this;
      if (circular) {
        that.interavl = setInterval(() => {
          const juli = that.config.left - that.swiperWidth;
          that.replaceStyle(juli, 0.4);
          setTimeout(() => {
            that.setData(1, juli);
          }, that.swiperAnimationTime);
        }, time);
      } else {
        that.interavl = setInterval(() => {
          if (!this.$props.zanting) {
            let juli = that.config.left - that.swiperWidth;
            let flag = '';
            if (that.config.index >= that.config.list.length - 1) {
              juli = 0;
              flag = 'fang';
            }
            that.replaceStyle(juli, 0.4);
            setTimeout(() => {
              that.setData(1, juli, flag);
            }, that.swiperAnimationTime);
          }
        }, time);
      }
    },
    // 点击切换
    dianClick(index) {
      if (this.config.index !== index) {
        let juli = -(index * this.swiperWidth);
        if (this.swiperCircular) {
          juli = -((index + 1) * this.swiperWidth);
        }
        this.config.left = juli;
        this.config.index = index;
        this.replaceStyle(juli, 0.4);
      }
    },
    // 点击图片
    imgClick(index) {
      const leng = this.config.list.length - 2;
      let num = index - 1;
      if (index >= leng + 1) {
        num = 0;
      }
      if (index === 0) {
        num = leng - 1;
      }
      this.$emit('SwiperFun', num);
    },
    // 点击左右箭头
    arrowsClick(flag) {
      if (flag === 'left') {
        // 前进
        if (this.swiperCircular) {
          // 首位连接
          const juli = this.config.left - this.swiperWidth;
          this.replaceStyle(juli, 0.4);
          this.setData(2, juli);
        } else {
          // 首位不连接
          let juli = this.config.left + -(this.swiperWidth);
          let flag = '';
          if (this.config.index >= this.config.list.length - 1) {
            juli = 0;
            flag = 'fang';
            return;
          }
          this.replaceStyle(juli, 0.4);
          this.setData(2, juli, flag);
        }
      } else {
        // 后退
        if (this.swiperCircular) {
          // 首位连接
          const juli = this.config.left + this.swiperWidth;
          this.replaceStyle(juli, 0.4);
          this.setData(2, juli);
        } else {
          // 首位不连接
          let juli = this.config.left - -(this.swiperWidth);
          if (this.config.index === 0) {
            juli = -((this.config.list.length - 1) * this.swiperWidth);
            return;
          }
          this.replaceStyle(juli, 0.4);
          this.setData(2, juli);
        }
      }
    },
  },

  // 组件实例化完毕，但页面还未显示（请求数据）
  created() {
    let h = this.$props.swiperConfig.height;
    let w = this.$props.swiperConfig.width;
    if (!isNaN(h)) {
      h = h + 'px';
    }
    if (!isNaN(w)) {
      w = w + 'px';
    }
    this.templateStyle = `height: ${h};
                          width: ${w};`;
  },
  mounted() {
    if (this.$props.list.length > 0) {
      this.initialData();
      if (this.$props.swiperConfig.autoplay) {
        if (this.$props.swiperConfig.interval) {
          this.interavlTime = this.$props.swiperConfig.interval;
        }
        this.autoplayFun(this.interavlTime, this.$props.swiperConfig.circular);
      }
    }
  },

  // 组件销毁前
  beforeDestroy() {
    clearInterval(this.interavl);
  },
};
</script>
