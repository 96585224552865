


























































declare let AMap: any;
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapIcon } from '@/assets/model/interface';
import { getSiteAll, warmListClass } from '@/http/adminApi';

@Component({
  components: {},
})
export default class Index extends Vue {
  @Prop(Number) public readonly type!: Number;
  @Prop(String) public readonly district!: String;
  @Prop(Array) public readonly center: any;
  @Prop(Number) public readonly zoom?: Number;
  @Prop() public readonly markersData?: any;

  public zkzClass: any = {
    '1': '街道（乡镇）和社区职康劳动项目',
    '2': '民办职康劳动项目',
    '3': '依托社会福利、卫生医疗、教育等机构职康劳动项目'
  };
  public mask: any = [];
  public mapCenter: any = '';
  public mapData: Array<any> = [];
  public polygons: Array<any> = [];
  public dispatch: any = '';
  public markers: Array<object> = [];
  public window: any = '';
  public amapText: any = '';
  public mapZoom: Number = 11;
  // public 
  public events: object = {
    'click': (e: any) => { // 点击地图的时候，获取点击的经纬度，并将地图中心点移自此处
      // console.log('点击地图')
      // console.log(e)
    },
    'zoomend': (e: any) => {
      let that = this
      // console.log(that.$refs.map.$amap.getZoom())
      // that.zoom = that.$refs.map.$amap.getZoom()
    }
  }
  public cityClick: object = {
    'mouseover': (e: any) => {
      let that = this
      let lng = e.target.w.position.lng + ''
      let lat = e.target.w.position.lat + ''
      that.setAmapText(lng, lat)
    },
    'mouseout': (e: any) => {
      let that = this
      that.amapTextHeid()
    },
    'click': (e: any) => {
      if (!this.type) {
        return
      }
      let that = this
      let lng = e.target.De.position.lng + ''
      let lat = e.target.De.position.lat + ''
      that.window = ''
      that.setWindow(lng, lat)
    }
  }
  @Watch('type')
  typeWatch(n: any, o: any) {
    this.markers = []
    if (n == 1) {
      this.sumgetSiteAll()
    } else {
      let str: string = 'wh'
      if (n == 3) {
        str = 'zkz'
      }
      if (n == 4) {
        str = 'kf'
      }
      this.sumgetSiteClass(str, n)
    }
  }

  private created(): void {
    this.mapCenter = this.center
    if (this.zoom) {
      this.mapZoom = this.zoom
    }
    if (this.district) {
      this.drawBounds(this.district)
    }
    if (this.markersData) {
      this.mapData = this.markersData
      this.setMarkers()
    }
    if(!this.type) {
      return
    }
    if (this.type == 1) {
      this.sumgetSiteAll()
    } else {
      let str: string = 'wh'
      if (this.type == 3) {
        str = 'zkz'
      }
      if (this.type == 4) {
        str = 'kf'
      }
      this.sumgetSiteClass(str, this.type)
    }
  }
  public setWindow(lng: any, lat: any) {
    this.mapData.forEach((val: any, ind) => {
      if (val.location[1] == lng && val.location[0] == lat) {
        this.amapTextHeid()
        let content: string = '<div class="windows-text">' + 
        '<div class="text-map">' +
          '<span style="width: 1.3rem;">所属: </span><span>温馨家园</span>' +
        '</div>' +
        '<div class="text-map">' +
          '<span style="width: 1.3rem;">名称: </span><span>' + val.name +'</span>' +
        '</div>' +
        '<div class="text-map">' +
          `<span style="width: 2.3rem;">服务指导老师数: </span><span>${val.teacherNumber || '--'} 人</span>` +
        '</div>' +
        '<div class="text-map">' +
          `<span style="width: 2.3rem;">长期服务志愿者人数: </span><span>${val.volunteerNumber || '--'} 人</span>` +
        '</div>' +
        '<div class="text-map">' +
          `<span style="width: 1.3rem;">总面积: </span><span>${val.volunteerNumber || '--'} 平方米</span>` +
        '</div>' +
        '<div class="text-map">' +
          `<span style="width: 1.3rem;">管理人数: </span><span>${val.totalNumber || '--'} 人</span>` +
        '</div>' +
        '<div class="text-map">' +
          `<span style="width: 2.3rem;">家园管理人员姓名: </span><span>${val.contacts || '--'}</span>` +
        '</div>' +
        '<div class="text-map">' +
          `<span style="width: 2.3rem;">家园管理人员电话: </span><span>${val.phone || '--'}</span>` +
        '</div>' +
        '<div class="bottoms">' +
          '<div id="video1" data-flag="' + val.flag + '" data-deviceSerial="' + val.deviceSerial1 + '" data-id="' + val.id + '" data-name="' + val.name + '监控一" class="bottom-item video">监控一</div>' +
          '<div id="video2" data-flag="' + val.flag + '" data-deviceSerial="' + val.deviceSerial2 + '" data-id="' + val.id + '" data-name="' + val.name + '监控二" class="bottom-item video">监控二</div>' +
          '<div id="detail" data-flag="' + val.flag + '" data-id="' + val.id + '" data-name="' + val.name + '" class="bottom-item">查看详情</div>' +
        '</div>' +
        '</div>';
        if (val.flag == 3) {
          content = `<div class="windows-text">
            <div class="text-map">
              <span style="width: 1rem;">所属: </span><span>职康站</span>
            </div>
            <div class="text-map">
              <span style="width: 1rem;">名称: </span><span>${val.name || '--'}</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">是否帮扶基地: </span><span>${val.volunteerNumber == 1?'是':'否'}</span>
            </div>
            <div class="text-map">
              <span style="width: 1.3rem;">职康站类别: </span><span>${this.zkzClass[val.type]}</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">服务对象: </span><span>${this.setServiceObject(val.serviceObject)}</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">职康站人员数: </span><span> ${val.number || '--'} 人</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">总面积: </span><span>${val.area || '--'} 平方米</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">管理人员姓名: </span><span>${val.contacts || '--'}</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">管理人员电话: </span><span>${val.phone || '--'}</span>
            </div>
            <div class="bottoms">
              <div id="video1" data-flag="${val.flag}" data-deviceSerial="${val.deviceSerial1}" data-id="${val.id}" data-name="${val.name}监控一" class="bottom-item video">监控一</div>
              <div id="video2" data-flag="${val.flag}" data-deviceSerial="${val.deviceSerial2}" data-id="${val.id}" data-name="${val.name}监控二" class="bottom-item video">监控二</div>
              <div id="detail" data-flag="${val.flag}" data-id="${val.id}" data-name="${val.name}" class="bottom-item">查看详情</div>
            </div>
          </div>`;
        }
        if (val.flag == 4) {
          content = `<div class="windows-text">
            <div class="text-map">
              <span style="width: 1rem;">所属: </span><span>康复机构</span>
            </div>
            <div class="text-map">
              <span style="width: 1rem;">名称: </span><span>${val.name || '--'}</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">管理人员姓名: </span><span>${val.contacts || '--'}</span>
            </div>
            <div class="text-map">
              <span style="width: 2.3rem;">管理人员电话: </span><span>${val.phone || '--'}</span>
            </div>
            <div class="bottoms">
              <div id="video1" data-flag="${val.flag}" data-deviceSerial="${val.deviceSerial1}" data-id="${val.id}" data-name="${val.name}监控一" class="bottom-item video">监控一</div>
              <div id="video2" data-flag="${val.flag}" data-deviceSerial="${val.deviceSerial2}" data-id="${val.id}" data-name="${val.name}监控二" class="bottom-item video">监控二</div>
              <div id="detail" data-flag="${val.flag}" data-id="${val.id}" data-name="${val.name}" class="bottom-item">查看详情</div>
            </div>
          </div>`;
        }
        this.window = {
          position: [val.location[1], val.location[0]],
          visible: false,
          content: content,
          offset: [0, -5]
        }
        this.$nextTick(function() {
          this.window.visible = true
          this.getVideo()
        })
      }
    })
  }
  public setServiceObject(str: String) {
    const arr: any = []
    let enumData: any = {
      '1': '视力残疾',
      '2': '听力残疾',
      '3': '言语残疾',
      '4': '肢体残疾',
      '5': '智力残疾',
      '6': '精神残疾',
      '7': '多重残疾'
    }
    const strArr: any = str.split(',')
    strArr.forEach((element: any) => {
      arr.push(enumData[element])
    });
    return arr.join(',')
  }
  public setAmapText(lng: any, lat: any) {
    this.mapData.forEach((val: any, ind) => {
      if (val.location[1] == lng && val.location[0] == lat) {
        this.amapText = {
          offset: [0, -22],
          text: val.name,
          position: [val.location[1], val.location[0]],
        }
      }
    })
  }
  public amapTextHeid() {
    this.amapText = ''
  }
  public getVideo() {
    const that = this
    setTimeout(() => {
      // const videoDom: any = document.getElementsByClassName("video")
      const dom: any =  document.getElementById("detail")
      const videoDom1: any =  document.getElementById("video1")
      const videoDom2: any =  document.getElementById("video2")
      // dom.setAttribute('data-hh', '我很帅')
      dom.addEventListener('click', function(e: any) {
        let obj: any = {
          type: 'detail',
          id: e.target.getAttribute('data-id'),
          name: e.target.getAttribute('data-name'),
          flag: e.target.getAttribute('data-flag'),
        }
        that.$emit('DialogShowFun', obj);
      })
      videoDom1.addEventListener('click', function(e: any) {
        let obj: any = {
          type: 'video',
          id: e.target.getAttribute('data-id'),
          deviceSerial: e.target.getAttribute('data-deviceSerial'),
          name: e.target.getAttribute('data-name'),
          flag: e.target.getAttribute('data-flag'),
        }
        that.$emit('DialogShowFun', obj)
      })
      videoDom2.addEventListener('click', function(e: any) {
        let obj: any = {
          type: 'video',
          id: e.target.getAttribute('data-id'),
          deviceSerial: e.target.getAttribute('data-deviceSerial'),
          name: e.target.getAttribute('data-name'),
          flag: e.target.getAttribute('data-flag'),
        }
        that.$emit('DialogShowFun', obj)
      })
    }, 200);
  }
  public sumgetSiteAll() {
    getSiteAll({}, 'kf').then((res: any) => {
      if (res) {
        const arr: any = []
        if (res.result.whList) {
          res.result.whList.forEach((element: any, index: number) => {
            element.flag = 2
            element.location = this.setLocation(element.location)
            arr.push(element)
          });
        }
        if (res.result.zkzList) {
          res.result.zkzList.forEach((element: any) => {
            element.flag = 3
            element.location = this.setLocation(element.location)
            arr.push(element)
          });
        }
        if (res.result.kfList) {
          res.result.kfList.forEach((element: any) => {
            element.flag = 4
            element.location = this.setLocation(element.location)
            arr.push(element)
          });
        }
        this.mapData = arr
        this.setMarkers()
      }
    })
  }
  public setLocation(e: String):any {
    if (!e) {
      return ''
    }
    const arr =  e.split(',')
    arr[0] = arr[0].split('.')[0] + '.' + arr[0].split('.')[1].substr(0, 6)
    arr[1] = arr[1].split('.')[0] + '.' + arr[1].split('.')[1].substr(0, 6)
    return arr
  }
  public sumgetSiteClass(str: String, flag: Number) {
    warmListClass({}, str).then((res: any) => {
      if(res) {
        const arr: any = []
        if (res.result) {
          res.result.forEach((element: any) => {
            element.flag = flag
            element.location = this.setLocation(element.location)
            arr.push(element)
          });
        }
        this.mapData = arr
        this.setMarkers()
      }
    })
  }
  public drawBounds(city: String) {
    let that = this
    if (!that.dispatch) {
      let opts = {
        subdistrict: 0,
        extensions: 'all',
        level: 'city'
      }
      that.dispatch = new AMap.DistrictSearch(opts)
      that.dispatch.search(city, function (status: string, resut: any) {
        let array: Array<object> = []
        let bounds:Array<any> = []
        let mask:Array<any> = []
        bounds = resut.districtList[0].boundaries
        for (let i = 0; i < bounds.length; i++) {
          let polygon = new AMap.Polygon({
            strokeWeight: 4,
            bubble: true,
            zIndex: 100,
            path: bounds[i],
            // fillOpacity: 0.2,
            // fillColor: '#c9ebf7',
            // strokeColor: '#4ea8f8'#74B9F6;
            strokeColor: '#74B9F6'
          })
          array.push(polygon)
          mask.push(bounds[i])
        }
        that.polygons = array
        that.mask = mask

      })
    }
  }
  public setMarkers() {
    // flag: 2 温馨家园  3 之抗战  4康复机构
    let iconUrl2: any = require('../../assets/images/tab1.png')
    let iconUrl3: any = require('../../assets/images/tab2.png')
    let iconUrl4: any = require('../../assets/images/tab3.png')
    let arr: Array<object> = []
    this.mapData.forEach((val: any, ind: number) => {
      let iconUrl = iconUrl2
      if(val.flag == 3 || val.flag == '3') {
        iconUrl = iconUrl3
      }
      if(val.flag == 4 || val.flag == '4') {
        iconUrl = iconUrl4
      }
      if (val.location) {
        let obj: object = {
          lnglat: [val.location[1], val.location[0]],
          icon: this.setIcon(iconUrl, 36, 50),
          id: val.id
        }
        arr.push(obj)
      }
    })
    this.markers = arr
  }
  setIcon(icon: any, x: number, y: number):object {
    let iconObj = new AMap.Icon({
      size: new AMap.Size(x, y),
      image: icon,
      imageSize: new AMap.Size(x, y),
    })
    return iconObj
  }
}
