


































































































































import EZUIKit from 'ezuikit-js';
import Echarts from '@/components/echarts/Echarts.vue';
import EchartsA from '@/components/echarts/EchartsA.vue';
import EchartsB from '@/components/echarts/EchartsB.vue';
import Map from '@/components/map/Map.vue';
import Swiper from '@/components/swiper/Swiper.vue';
import {
  showWarmHome,
  getEzopen,
  typeListClass,
  signAllCountClass,
  numberSumAllClass,
  toDayPageClass,
  serveNumberList
} from '@/http/adminApi';

import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Echarts,
    EchartsA,
    EchartsB,
    Map,
    Swiper,
  },
})
export default class Detail extends Vue {
  public mapCenter: any = '';
  public markersData: any = '';
  public swiperConfig: any = {
    type: 'image',
    index: 0, // 指定显示的索引
    autoplay: true, // 自动播放 默认false
    interval: 4000, // 自动播放的间隔 默认3000
    circular: true, // 首位衔接 默认false
    animationTime: 300, // 动画时长
    indicator: true, // 指示点 默认false
    arrows: true, // 左右箭头 默认false
    height: '100%',
    width: '100%',
  };
  public swiperArr: any = [];
  public chart1DataxAxis: any[] = [];
  public chart1Dataseries: any = '';
  public chart2DataxAxis: any = {};
  public chart2Dataseries: any[] = [];
  public chart3DataxAxis: any = {};
  public chart3Dataseries: any[] = [];
  public urlBef: string = '';
  public id: string = '';
  public title: string = '';
  public introduction: string = '';
  public daily: string = '';
  public videoData: any = [
    {
      id: 'player0',
      name: '视频监控一',
      state: 1, // 1 加载  2 正常  3 异常
      path: '',
      player: '',
    },
    {
      id: 'player1',
      name: '视频监控二',
      state: 1, // 1 加载  2 正常  3 异常
      path: '',
      player: '',
    },
  ];

  private created() {
    this.$store.commit('setLoading', true);
    this.id = this.$route.query.id + '';
    let str: string = 'wh';
    if (this.$route.query.flag === '3') {
      str = 'zkz';
    }
    if (this.$route.query.flag === '4') {
      str = 'kf';
    }
    this.title = this.$route.query.name + '';
    this.urlBef = str;
    this.getData();
  }
  // 组件销毁前
  private beforeDestroy() {
    this.videoData.forEach((val: any, ind: number) => {
      (document.getElementById(val.id) as any).innerHTML = '';
      val.state = 1;
      val.player = '';
    })
  }
  private getData() {
    this.getShowWarmHome();
    this.gettypeListClass();
    this.getsignAllCountClass();
    if (this.$route.query.flag === '2' || this.$route.query.flag === '3') {
      this.getnumberSumAllClass();
      this.gettoDayPageClass();
    }
  }

  private getShowWarmHome() {
    showWarmHome({ id: this.id }, this.urlBef).then((res: any) => {
      this.$store.commit('setLoading', false);
      if (res) {
        const location: string[] = res.result.location.split(',');
        const obj = {
          deviceSerial1: res.result.deviceSerial1,
          deviceSerial2: res.result.deviceSerial2,
          flag: this.$route.query.flag,
          location,
        };
        this.markersData = [obj];
        this.mapCenter = [location[1], (parseFloat(location[0]) - 0.018) + ''];
        if (res.result.img) {
          const imgArr: any = res.result.img.split(',');
          const swiperArr: any = [];
          imgArr.forEach((element: string) => {
            swiperArr.push({
              image: process.env.VUE_APP_IMG_URL + element,
              // image: window.location.hostname + '/api/system/static/' + element,
              url: '',
            });
          });
          this.swiperArr = swiperArr;
        }
        this.introduction = res.result.introduction;
        this.videoData[0].path = res.result.deviceSerial1;
        this.videoData[1].path = res.result.deviceSerial2;
        this.itemVideo();
      }
    });
  }
  private itemVideo() {
    this.videoData.forEach((value: any, index: number) => {
      getEzopen({ whId: value.path }).then((res: any) => {
        if (res) {
          value.player = new EZUIKit.EZUIKitPlayer({
            id: value.id, // 视频容器ID
            accessToken: res.result.token,
            url: res.result.url,
            audio: 1,
            handleSuccess: (e: any) => {
              value.state = 2;
            },
            handleError: (e: any) => {
              value.state = 3;
            },
          });
        } else {
          value.state = 3;
        }
      });
    });
  }
  private gettypeListClass() {
    if (this.$route.query.flag === '4') {
      typeListClass({ id: this.id }, this.urlBef).then((res: any) => {
        if (res) {
          const enumData: any = {
            1: '视力',
            2: '听力',
            3: '言语',
            4: '肢体',
            5: '智力',
            6: '精神',
            7: '多重',
          };
          const data: any[] = [];
          let num: number = 0;
          res.result.forEach((element: any) => {
            // num = parseInt(element.ctypeSum) + num;
            num = element.ctypeSum + num;
            data.push(
              { value: element.ctypeSum, name: enumData[element.ctype] },
            );
          });
          this.chart1DataxAxis = data;
          this.chart1Dataseries = num;
        }
      });
    } else {
      serveNumberList({ id: this.id }, this.urlBef).then((res: any) => {
        if (res) {
          const data: any[] = [
            { value: res.result.type1, name: '基本服务' },
            { value: res.result.type2, name: '赋能服务' },
            { value: res.result.type3, name: '互助服务' },
            { value: res.result.type4, name: '照料服务' },
            { value: res.result.type5, name: '项目服务' },
            { value: res.result.type5, name: '其他帮助' }
          ];
          let num: number = res.result.activityNumber;
          this.chart1DataxAxis = data;
          this.chart1Dataseries = num;
        }
      });
    }
    // serveNumberList[Symbol]...................
  }

  private getsignAllCountClass() {
    signAllCountClass({ id: this.id }, this.urlBef).then((res: any) => {
      if (res) {
        const data = res.result;
        const dataa = this.ssDate(data);
        this.chart2DataxAxis = dataa;
        if (this.$route.query.flag === '4') {
          this.chart3DataxAxis = dataa;
        }
      }
    });
  }

  private ssDate(data: any): any {
    const dataObj: any = {};
    dataObj.today = data.today;
    dataObj.shWeek = data.shWeek;

    dataObj.thisWeek = data.thisWeek;
    dataObj.weekPerCent = setPerCent(data.shWeek, data.thisWeek);

    dataObj.shMonth = data.shMonth;
    dataObj.thisMonth = data.thisMonth;
    dataObj.monthPerCent = setPerCent(data.shMonth, data.thisMonth);

    dataObj.shQuarter = data.shQuarter;
    dataObj.thisQuarter = data.thisQuarter;
    dataObj.quarterPerCent = setPerCent(data.shQuarter, data.thisQuarter);

    return dataObj;

    function setPerCent(s: number, b: number): any {
      if (!s || !b) return '0%'
      const num = b - s;
      let numm: any = '';
      if (s === 0) {
        numm = (num / 1 * 100).toFixed(2) + '';
      } else {
        numm = (num / s * 100).toFixed(2) + '';
      }
      const sss = numm.split('.');
      const oo = sss[1].substring(0, 1);
      const aa = sss[1].substr(1);
      let val = sss[0];
      if (aa === '0') {
        if (oo !== '0') {
          val = val + '.' + oo;
        }
      } else {
        val = val + '.' + oo + aa;
      }
      return val + ' %';
    }
  }

  private getnumberSumAllClass() {
    numberSumAllClass({ id: this.id }, this.urlBef).then((res: any) => {
      if (res) {
        const data = res.result;
        const dataA = this.ssDate(data);
        this.chart3DataxAxis = dataA;
      }
    });
  }

  private gettoDayPageClass() {
    toDayPageClass({ id: this.id }, this.urlBef).then((res: any) => {
      if (res) {
        let str: string = '';
        if (res.result.records) {
          res.result.records.forEach((element: any, index: number) => {
            if (index === 0) {
              str = element.content;
            }
          });
        }
        if (!str) {
          str = '暂无日报';
        }
        this.daily = str;
      }
    });
  }
  private goBack() {
    this.$router.go(-1);
  }
}
